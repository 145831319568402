<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('ENTRIES') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <v-select
              id="id-userbatch"
              v-model="userBatch"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :placeholder="$t('SELECT_BATCH')"
              label="file_name"
              :clearable="false"
              :options="userBatchies"
              @input="fetchRecords()"
            />
          </b-col>
          <b-col>
            <v-select
              id="id-game"
              v-model="game"
              class="ml-1"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :placeholder="$t('SELECT_CONTENT')"
              label="game_name"
              :clearable="false"
              :options="games"
              style="min-width: 200px;"
              @input="fetchGameRecords()"
            />

          </b-col>
        </b-row>

      </div>
    </b-card>
    <b-card
      v-if="userBatch.ID"
      no-body
    >
      <!-- Header -->
      <b-card-body class="invoice-padding pb-0">

        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

          <!-- Header: Left Content -->
          <div>
            <p>
              <b-media
                no-body
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    variant="light-success"
                    size="34"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="ListIcon"
                      size="18"
                      @click="fetchRecords()"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <b-link
                    @click="fetchRecords()"
                  >
                    <span>{{ userBatch.record_status === 2 ? $t('TOTAL_RECORDS') + ' : ' + (userBatch.player_success_count + userBatch.player_exists_count + userBatch.player_fail_count) : $t('TOTAL_RECORDS') + ' : ' + (userBatch.users_success_count + userBatch.users_exists_count + userBatch.users_fail_count) }}</span>
                  </b-link>
                </b-media-body>
              </b-media>
            </p>
            <p>
              <b-media
                no-body
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    variant="light-success"
                    size="34"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="ThumbsUpIcon"
                      size="18"
                      @click="fetchSuccessfulRecords()"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <b-link
                    @click="fetchSuccessfulRecords()"
                  >
                    <span>{{ userBatch.record_status === 2 ? $t('TOTAL_NEW_UPLOADED_RECORDS') + ' : ' + userBatch.player_success_count : $t('TOTAL_NEW_RECORDS') + ' : ' + userBatch.users_success_count }}</span>

                  </b-link>
                </b-media-body>
              </b-media>

            </p>
            <p>
              <b-media
                no-body
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    variant="light-info"
                    size="34"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="UsersIcon"
                      size="18"
                      @click="fetchExistsRecords()"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <b-link
                    @click="fetchExistsRecords()"
                  >
                    <span>{{ userBatch.record_status === 2 ? $t('TOTAL_EXISTS_UPLOADED_RECORDS') + ' : ' + userBatch.player_exists_count : $t('TOTAL_EXISTS_RECORDS') + ' : ' + userBatch.users_exists_count }}</span>

                  </b-link>
                </b-media-body>
              </b-media>

            </p>
            <p>

              <b-media
                no-body
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    variant="light-danger"
                    size="34"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="ThumbsDownIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mt-0 pt-50">
                    {{ userBatch.record_status === 2 ? $t('TOTAL_FAIL_UPLOADED_RECORDS') + ' : ' + userBatch.player_fail_count : $t('TOTAL_FAIL_RECORDS') + ' : ' + userBatch.users_fail_count }}
                  </p>
                </b-media-body>
              </b-media>
            </p>
            <p v-if="userBatch.template_id">
              <b-media
                no-body
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="AwardIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p
                    class="mt-0 pt-50"
                  > {{ $t('TOTAL_GAMES_AND_TEAMS') }} : {{ userBatch.game_count }}
                  </p>
                </b-media-body>
              </b-media>
            </p>
            <p>
              <b-media
                no-body
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mt-0 pt-50">
                    {{ $t('UPLOADED_ON') }} : {{ userBatch.record_status === 2 ? formatDateTime(userBatch.modified_timestamp) : formatDateTime(userBatch.finish_at) }}
                  </p>
                </b-media-body>
              </b-media>
            </p>

          </div>

          <!-- Header: Right Content -->
          <div class="mt-md-0 mt-2">
            <b-badge
              pill
              :variant="`light-${resolveUploadUserStatusVariant(userBatch.record_status).variant}`"
              class="text-capitalize"
            >
              {{ $t('BATCH') }} # {{ userBatch.ID }} - {{ resolveUploadUserStatusVariant(userBatch.record_status).name }}
            </b-badge>
          </div>
        </div>
        <!-- submit and delete -->
        <b-col class="mb-1 text-right">
          <b-button
            v-if="userBatch.record_status===1"
            class="ml-auto mr-1"
            type="submit"
            variant="primary"
            :disabled="!isUploadActive()"
            @click="submit()"
          >
            {{ submitText }}
          </b-button>
          <b-button
            v-if="userBatch.record_status===1"
            class="ml-auto"
            type="submit"
            variant="primary"
            @click="onDeleteBatch()"
          >
            {{ $t('BUTTON.DELETE') }}
          </b-button>
        </b-col>
      </b-card-body>
    </b-card>
    <b-table
      ref="refUserListTable"
      :items="userUploads"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      class="position-relative"
    >

      <!-- Column: User -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.first_name +' '+ data.item.last_name)"
              :variant="`light-${resolveUploadUserStatusVariant(data.item.record_status).variant}`"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.first_name }}  {{ data.item.last_name }}
          </b-link>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>

      <!-- Column: Role -->
      <template #cell(Role)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.role }}
          </span>
        </b-media>
      </template>

      <!-- Column: Business unit -->
      <template #cell(BusinessUnit)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.business_unit }}
          </span>
        </b-media>
      </template>

      <!-- Column:Phonenumber -->
      <template #cell(Phonenumber)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.country_code }}{{ data.item.phonenumber.replace(/^0+/, '') }}
          </span>
        </b-media>
      </template>

      <!-- Column: Comment -->
      <template #cell(Comment)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.comment,15) }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            v-if="data.item.user_id"
            :id="`invoice-row-${data.item.user_id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mr-1 cursor-pointer"
            @click="$router.push({ name: 'user-profile-id', params: { id: data.item.user_id }})"
          />
          <feather-icon
            v-if="userBatch.record_status === 1"
            :id="`game-row-${data.item.ID}-preview-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer"
            @click="onDeleteUser(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>

import {
  BCard, BRow, BCol,
  BMediaAside, BMediaBody,
  BTable, BMedia, BAvatar, BLink, BCardBody,
  BPagination, BButton, BBadge,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-extraneous-dependencies, import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import { ref, computed } from '@vue/composition-api'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/named
import { resolveUploadUserStatusVariant } from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BTable,
    BMedia,
    BPagination,
    vSelect,
    BAvatar,
    BLink,
    BMediaAside,
    BMediaBody,
    BButton,
    BBadge,
  },
  mixins: [mixinDate, mixinList],
  data() {
    return {
      userData: useJwt.getUser(),
      userBatchies: [],
      userUploads: [],
      games: [],
      game: '',
      submitText: 'Submit',
      userBatch: {
        ID: '',
        file_name: '',
        users_success_count: 0,
        users_exists_count: 0,
        users_fail_count: 0,
        player_success_count: 0,
        player_exists_count: 0,
        player_fail_count: 0,
        game_count: 0,
        finish_at: '',
        record_status: 0,
      },
      searchQuery: '',
    }
  },
  setup() {
    const tableColumns = [
      { key: 'Name', sortable: false },
      { key: 'Role', sortable: false },
      { key: 'BusinessUnit', sortable: false },
      { key: 'Phonenumber', sortable: false },
      { key: 'Comment', sortable: false },
      { key: 'Actions' },
    ]

    const { PER_PAGE_OPTIONS, PER_PAGE } = constants
    const refUserListTable = ref(null)

    const perPage = ref(PER_PAGE)
    const totalUsers = ref(0)
    const currentPage = ref(1)
    const perPageOptions = PER_PAGE_OPTIONS

    const dataMeta = computed(() => {
      const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalUsers.value,
      }
    })

    const refetchData = () => {
      refUserListTable.value.refresh()
    }

    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      refUserListTable,
      refetchData,
      successMessage,
      errorMessage,
      showErrorMessage,
      avatarText,
      resolveUploadUserStatusVariant,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.fetchUserUploadList(false)
      },
    },
  },
  mounted() {
    this.fetchUploadUserBatch()
  },
  methods: {
    ...mapActions('user', ['fetchUserBatch', 'fetchGameByBatchID', 'fetchUserUploads', 'uploadUserConfirmation', 'deleteUserBatch', 'deleteUserUpload']),
    fetchUploadUserBatch() {
      this.fetchUserBatch().then(response => {
        if (response) {
          const newData = response.data.map(item => ({
            ...item,
            file_name: `Batch # ${item.ID} 
            | ${item.file_name.split('-').slice(1).join('-')}`,
          }))
          this.userBatchies = newData
          // eslint-disable-next-line prefer-destructuring
          this.userBatch = this.userBatchies[0]
          this.fetchUserUploadList(true)
        }
      })
    },
    submit() {
      const message = this.userBatch.template_id > 0
        ? `Do you want to confirm the upload? You're attempting to upload ${this.userBatch.users_success_count} players with ${this.userBatch.game_count} games. Are you sure you want to proceed?`
        : `Do you want to confirm the upload? You're attempting to upload ${this.userBatch.users_success_count} players. Are you sure you want to proceed?`
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.uploadUserConfirmation(this.userBatch.ID).then(response => {
              if (response) {
                this.userBatch.record_status = constants.RECORD_STATUS.COMPLETED
                this.successMessage(this.$i18n.t('MESSAGE.UPLOAD_USER_SUCCESSFULLY'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    onDeleteBatch() {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${this.userBatch.file_name}  ${this.$i18n.t('BATCH')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteUserBatch(this.userBatch.ID).then(response => {
              if (response.data.status) {
                this.fetchUploadUserBatch()
                this.successMessage(this.$i18n.t('MESSAGE.USER_BATCH_DELETED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    onDeleteUser(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.first_name} ${data.last_name}  ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteUserUpload(data.ID).then(response => {
              if (response.data.status) {
                this.fetchUploadUserBatch()
                this.successMessage(this.$i18n.t('MESSAGE.USER_DELETED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    setButton() {
      this.submitText = this.userBatch.template_id > 0
        ? `Proceed ${this.userBatch.users_success_count} players with ${this.userBatch.game_count} games`
        : `Proceed ${this.userBatch.users_success_count} players`
    },
    isUploadActive() {
      return (
        (this.userBatch.template_id > 0 && (this.userBatch.users_success_count > 0 || this.userBatch.users_exists_count))
    || (this.userBatch.template_id === 0 && this.userBatch.users_success_count > 0)
      )
    },
    fetchFailRecords() {
      this.searchQuery = `&record_status=${constants.RECORD_STATUS.ERROR_ROW}`
      this.fetchUserUploadList(false)
    },
    fetchExistsRecords() {
      this.searchQuery = `&record_status=${constants.RECORD_STATUS.DUPLICATE_ROW}`
      this.fetchUserUploadList(false)
    },
    fetchSuccessfulRecords() {
      this.searchQuery = `&record_status=${constants.RECORD_STATUS.ACTIVE}`
      this.fetchUserUploadList(false)
    },
    fetchGameRecords() {
      this.searchQuery = `&game_name=${this.game.game_name}`
      this.fetchUserUploadList(false)
    },
    fetchRecords() {
      this.searchQuery = ''
      this.fetchUserUploadList(true)
    },
    fetchUploadGames() {
      const payload = {
        id: this.userBatch.ID,
        group_by: 'game_name',
      }
      this.game = ''
      this.games = []
      if (this.userBatch.template_id > 0) {
        this.fetchGameByBatchID(payload).then(response => {
          if (response.data) {
            this.game = ''
            this.games = response.data
            this.userBatch.game_count = this.games.length
            this.setButton()
          }
        })
      } else {
        this.setButton()
      }
    },
    fetchUserUploadList(isFetchGame) {
      const payload = {
        id: this.userBatch.ID,
        perPage: this.perPage,
        page: this.currentPage,
        searchQuery: this.searchQuery,
      }
      this.fetchUserUploads(payload).then(response => {
        if (response) {
          this.userUploads = response.data.result
          this.totalUsers = response.data.count
          if (isFetchGame) this.fetchUploadGames()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
